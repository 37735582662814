<template>
  <div>
    <v-card class="pa-4"
            outlined
            tile>
      <h3> Comissions </h3>
    </v-card>
    <hr>
    <v-card class="pa-4"
            outlined
            tile>
      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs6 md3 lg2 class="pb-0">
          </v-flex>
          <v-spacer></v-spacer>
<!--          <v-flex xs12 md5 lg4 class="pb-0">-->
<!--            <v-text-field-->
<!--                v-model.trim="search"-->
<!--                append-icon="search"-->
<!--                label="Search"-->
<!--                color="blue"-->
<!--                dense-->
<!--                clearable-->
<!--            ></v-text-field>-->
<!--          </v-flex>-->

          <v-flex xs12>
            <v-data-table :headers="headers"
                          :items="items"
                          :loading="loading"
                          :options.sync="options"
                          :search="search"
                          :server-items-length="total"
                          :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Items per page:'}"
                          class="elevation-1" loading-text="Loading...">
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Comissions',

  data: () => ({
    menu: false,
    total: 0,
    options: {
      itemsPerPage: 10,
    },
    search: '',
    rowsPerPageItems: [5, 10, 20],
    headers: [
      {
        text: 'Buy Date',
        align: 'left',
        value: 'buy_date',
        sortable: true
      },
      {
        text: 'Comission Type',
        align: 'left',
        value: 'comission_type',
        sortable: true
      },
      {
        text: 'Cupon',
        align: 'left',
        value: 'cupon',
        sortable: true
      },
      {
        text: 'Original Product',
        align: 'left',
        value: 'original_product',
        sortable: true
      },
      {
        text: 'First Comission Date',
        align: 'left',
        value: 'first_comission_date',
        sortable: true,
      },
      {
        text: 'First Comission Amount',
        align: 'left',
        value: 'first_comission_amount',
        sortable: false,
      },
      {
        text: 'First Comission Paid',
        align: 'left',
        value: 'first_comission_paid',
        sortable: true
      },
      {
        text: 'Next Comission Date',
        align: 'left',
        value: 'next_recurring_comission_date',
        sortable: true
      },
      {
        text: 'Recurring Comission Amount',
        align: 'left',
        value: 'recurring_comission_amount',
        sortable: true
      },
      {
        text: 'Recurring Comission Active',
        align: 'left',
        value: 'recurring_comission_active',
        sortable: true
      }
    ],
    items: [],
    loading: false,
    serverErrorCode: 0,
    serverErrorMessage: '',
    success: false,
 
  }),
  watch:{
    options: {
      handler() {
        this.getComissions()
      },
      deep: true,
    },
  }, 
  methods: {
    getComissions(){
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/comissions?items_per_page=" + this.options.itemsPerPage + "&page=" + this.options.page)
        .then(response => {
          this.loading = false;
          this.items = response.data.data
          this.total = response.data.meta.total
      }).catch(error => {
      console.log(error.response)
      });
    }
  },
  created(){
    this.loading = true
    this.getComissions()
  },
}
</script>
