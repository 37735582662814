<template>
  <div>
    <v-card class="pa-4"
            outlined
            tile>
      <h3> Wholesalers </h3>
    </v-card>
    <hr>
    <v-card class="pa-4"
            outlined
            tile>
      <v-container grid-list-lg>
        <v-alert type="success" v-show="success">
          Password sent to e-mail successfully.
        </v-alert>
        <v-alert type="success" v-show="createSuccess">
          Wholesaler created successfully.
        </v-alert>
        <v-layout row wrap>
          <v-flex xs6 md3 lg2 class="pb-0">
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
               <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    label="Month"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  type="month"
                  no-title
                  scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs6 md3 lg2 class="pb-0">
            <v-btn color="primary" @click="createDialog = true">NEW</v-btn>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 md5 lg4 class="pb-0">
            <v-text-field
                v-model.trim="search"
                append-icon="search"
                label="Search"
                color="blue"
                dense
                clearable
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-data-table :headers="headers"
                          :items="items"
                          :loading="loading"
                          :options.sync="options"
                          :search="search"
                          :server-items-length="total"
                          :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Items per page:'}"
                          class="elevation-1" loading-text="Loading...">

              <template v-slot:item.url="{ item }">
                <a :href="item.url" target="_blank">{{item.url}}</a>
              </template>

              <template v-slot:item.volume_subscription_sales="{item}">
                  {{item.volume_subscription_sales}}  €
              </template>

              <template v-slot:item.monthly_volume_search="{item}">
                  {{item.monthly_volume_search}}  €
              </template>

               <template v-slot:item.comission_percentage="{item}">
                  {{item.comission_percentage}}%
              </template>

              <template v-slot:item.montly_comission="{item}">
                  {{item.montly_comission}} €
              </template>

              <template v-slot:item.action="{ item }">
                <v-btn v-if="!item.email" small color="teal" class="mx-1" dark tile outlined
                       @click="openDialog(item)">Generate Password
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        width="500"
    >

      <v-card v-if="selectedItem">
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          Generate Password for {{ selectedItem.name }}
        </v-toolbar>
        <v-card-text class="pb-2 pt-8">
          <form>
            <v-text-field
                v-model="model.email"
                :error-messages="emailErrors"
                label="E-mail"
                required
                @input="$v.model.email.$touch()"
                @blur="$v.model.email.$touch()"
                outlined
                dense
            ></v-text-field>
            <v-text-field
                v-model="model.email_confirmation"
                :error-messages="emailConfirmationErrors"
                label="E-mail confirmation"
                required
                @input="$v.model.email_confirmation.$touch()"
                @blur="$v.model.email_confirmation.$touch()"
                outlined
                dense
            ></v-text-field>
          </form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
              class="ml-4 float-end"
              color="primary"
              @click="sendEmailPassword()"
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="createDialog"
        scrollable
        persistent
        width="500"
    >

      <v-card>
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          Create Wholesaler
        </v-toolbar>
        <v-card-text class="pb-2 pt-8">
          <form>
            <v-text-field
                v-model="createModel.name"
                :error-messages="createNameErrors"
                label="Name"
                required
                @input="$v.createModel.name.$touch()"
                @blur="$v.createModel.name.$touch()"
                outlined
                dense
            ></v-text-field>
            <v-text-field
                v-model="createModel.name_url"
                label="Name URL"
                required
                outlined
                dense
                disabled
            ></v-text-field>
            <v-text-field
                v-model="createModel.percentage"
                :error-messages="createPercentageErrors"
                label="Percentage"
                type="number"
                required
                @input="$v.createModel.percentage.$touch()"
                @blur="$v.createModel.percentage.$touch()"
                outlined
                dense
            ></v-text-field>
            <v-text-field
                v-model="createModel.email"
                :error-messages="createEmailErrors"
                label="E-mail"
                required
                @input="$v.createModel.email.$touch()"
                @blur="$v.createModel.email.$touch()"
                outlined
                dense
            ></v-text-field>
            <v-text-field
                v-model="createModel.email_confirmation"
                :error-messages="createEmailConfirmationErrors"
                label="E-mail confirmation"
                required
                @input="$v.createModel.email_confirmation.$touch()"
                @blur="$v.createModel.email_confirmation.$touch()"
                outlined
                dense
            ></v-text-field>
          </form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="closeCreateDialog()"
          >
            Close
          </v-btn>
          <v-btn
              class="ml-4 float-end"
              color="primary"
              @click="createWholesaler()"
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email, sameAs, between} from 'vuelidate/lib/validators'
const moment = require('moment');

export default {
  name: 'WholeSalers',

  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    total: 0,
    options: {
      itemsPerPage: 10,
    },
    selectedItem: null,
    dialog: false,
    model: {
      email: '',
      email_confirmation: '',
    },
    createModel: {
      name: '',
      name_url: '',
      percentage: '',
      email: '',
      email_confirmation: '',
    },
    createDialog: false,
    createSuccess: false,
    search: '',
    rowsPerPageItems: [5, 10, 20],
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
        sortable: true,
      },
      {
        text: 'E-mail',
        align: 'left',
        value: 'email',
        sortable: true,
      },
      {
        text: 'Reseller registration URL',
        align: 'left',
        value: 'url',
        sortable: false
      },
      {
        text: 'Active Resellers',
        align: 'left',
        value: 'active_resellers',
        sortable: false,
      },
      {
        text: 'Current volume',
        align: 'left',
        value: 'volume_subscription_sales',
        sortable: false
      },
      {
        text: 'Montly volume',
        align: 'left',
        value: 'monthly_volume_search',
        sortable: true
      },
      {
        text: 'Comission percentage',
        align: 'left',
        value: 'comission_percentage',
        sortable: true
      },
      {
        text: 'Montly comission',
        align: 'left',
        value: 'montly_comission',
        sortable: true
      },
      {
        text: 'Actions',
        align: 'left',
        value: 'action',
      },
    ],
    items: [],
    loading: false,
    serverErrorCode: 0,
    serverErrorMessage: '',
    success: false,
 
  }),
  mixins: [validationMixin],
  validations: {
    model: {
      email: {
        required, email
      },
      email_confirmation: {
        required,
        sameAsEmail: sameAs('email'),
      }
    },
    createModel: {
      name: {
        required,
      },
      percentage: {
        required,
        between: between(0, 100)
      },
      email: {
        required, email
      },
      email_confirmation: {
        required,
        sameAsEmail: sameAs('email'),
      }
    }
  },
  watch:{
    options: {
      handler() {
        console.log(this.options);
        this.getWholeSalers()
      },
      deep: true,
    },
    createModel: {
      handler() {
        this.createModel.name_url = this.slugify(this.createModel.name)
      },
      deep: true,
    },
    date(val){
      this.$refs.menu.save(val);
      this.getWholeSalers()
    },
    search() {
      this.loading = true
      this.getWholeSalers()
    },
  },
  computed: {
    createNameErrors() {
      const errors = []
      if (!this.$v.createModel.name.$dirty) return errors;
      !this.$v.createModel.name.required && errors.push('Name is required.');
      return errors
    },
    createPercentageErrors() {
      const errors = []
      if (!this.$v.createModel.percentage.$dirty) return errors;
      !this.$v.createModel.percentage.between && errors.push('Percentage must be between 0 and 100.');
      !this.$v.createModel.percentage.required && errors.push('Percentage is required.');
      return errors
    },
    createEmailErrors() {
      const errors = []
      if (!this.$v.createModel.email.$dirty) return errors;
      !this.$v.createModel.email.email && errors.push('Must be valid e-mail')
      !this.$v.createModel.email.required && errors.push('E-mail is required')
      return errors
    },
    createEmailConfirmationErrors() {
      const errors = []
      if (!this.$v.createModel.email_confirmation.$dirty) return errors;
      !this.$v.createModel.email_confirmation.sameAsEmail && errors.push('E-mails must match')
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.model.email.$dirty) return errors;
      !this.$v.model.email.email && errors.push('Must be valid e-mail')
      !this.$v.model.email.required && errors.push('E-mail is required')
      return errors
    },
    emailConfirmationErrors() {
      const errors = []
      if (!this.$v.model.email_confirmation.$dirty) return errors;
      !this.$v.model.email_confirmation.sameAsEmail && errors.push('E-mails must match')
      return errors
    },
  },
  methods: {
    getWholeSalers(){
      const sortBy = this.options.sortBy && this.options.sortBy.length ? '&order=' +  this.options.sortBy[0] +  '&desc=' + this.options.sortDesc[0] : '';
      this.axios.get(process.env.VUE_APP_API_URL + "/wholesalers?name="+ this.search + "&month_year=" +  moment(this.date).format("MM-YYYY") + sortBy)
        .then(response => {
          this.loading = false
          this.items = response.data.data
          this.total = response.data.meta.total
      }).catch(error => {
        console.log(error.response)
      });
    },
    sendEmailPassword(){
      this.submitAttempt = true;
      this.hasClientErrors = false;
      this.hasServerErrors = false;
      this.serverErrors = {};
      this.$v.model.$touch();
      if (!this.$v.model.$invalid) {
        const data = {
          email: this.model.email,
          email_confirmation: this.model.email_confirmation,
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/wholesaler/setPassword/" + this.selectedItem.id, data)
            .then(() => {
              this.success = true;
              this.closeDialog();
              this.getWholeSalers();
            }).catch(error => {
              this.serverErrors = error.response.data.errors;
              this.hasServerErrors = true;
            }
        );
      } else {
        this.hasClientErrors = true;
      }
    },
    createWholesaler(){
      this.submitAttempt = true;
      this.hasClientErrors = false;
      this.hasServerErrors = false;
      this.serverErrors = {};
      this.$v.createModel.$touch();
      if (!this.$v.createModel.$invalid) {
        const data = {
          name: this.createModel.name,
          name_url: this.createModel.name_url,
          percentage: this.createModel.percentage,
          email: this.createModel.email,
          email_confirmation: this.createModel.email_confirmation,
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/wholesaler/create", data)
            .then(() => {
              this.createSuccess = true;
              this.closeCreateDialog();
              this.getWholeSalers();
            }).catch(error => {
              this.serverErrors = error.response.data.errors;
              this.hasServerErrors = true;
            }
        );
      } else {
        this.hasClientErrors = true;
      }
    },
    openDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
      console.log("item: ", item);
    },
    closeDialog() {
      this.$v.$reset();
      this.email = '';
      this.email_confirmation = '';
      this.dialog = false;
    },
    closeCreateDialog() {
      this.$v.$reset();
      this.createDialog = false;
    },
    slugify(str) {
      str = str.replace(/^\s+|\s+$/g, '');

      // Make the string lowercase
      str = str.toLowerCase();

      // Remove accents, swap ñ for n, etc
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
      var to   = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
      for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      // Remove invalid chars
      str = str.replace(/[^a-z0-9 -]/g, '')
          // Collapse whitespace and replace by -
          .replace(/\s+/g, '-')
          // Collapse dashes
          .replace(/-+/g, '-');

      return str;
    }
  },
  created(){
    this.loading = true
    this.getWholeSalers()
  },
}
</script>
