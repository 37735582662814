<template>
    <div>
        <v-card class="pa-4"
                outlined
                tile>
            <h3> Create Admin </h3>
        </v-card>
        <hr>
        <v-card class="pa-4"
                outlined
                tile>
            <v-container grid-list-lg>
                <v-layout row wrap>
                    <v-flex xs6 class="mx-auto">
                        <form>
                            <v-text-field
                                    v-model="model.name"
                                    :error-messages="nameErrors"
                                    label="Name"
                                    required
                                    @input="$v.model.name.$touch()"
                                    @blur="$v.model.name.$touch()"
                                    outlined
                                    dense
                            ></v-text-field>
                            <v-text-field
                                    v-model="model.email"
                                    :error-messages="emailErrors"
                                    label="E-mail"
                                    required
                                    @input="$v.model.email.$touch()"
                                    @blur="$v.model.email.$touch()"
                                    outlined
                                    dense
                            ></v-text-field>
                            <v-text-field
                                    v-model="model.email_confirmation"
                                    :error-messages="emailConfirmationErrors"
                                    label="E-mail confirmation"
                                    required
                                    @input="$v.model.email_confirmation.$touch()"
                                    @blur="$v.model.email_confirmation.$touch()"
                                    outlined
                                    dense
                            ></v-text-field>
                            <v-text-field
                                    v-model="model.password"
                                    :error-messages="passwordErrors"
                                    label="Password"
                                    required
                                    type="password"
                                    @input="$v.model.password.$touch()"
                                    @blur="$v.model.password.$touch()"
                                    outlined
                                    dense
                            ></v-text-field>
                            <v-text-field
                                    v-model="model.password_confirmation"
                                    :error-messages="passwordConfirmationErrors"
                                    label="Password confirmation"
                                    required
                                    type="password"
                                    @input="$v.model.password_confirmation.$touch()"
                                    @blur="$v.model.password_confirmation.$touch()"
                                    outlined
                                    dense
                            ></v-text-field>
                            <v-btn
                                    class="ml-4 float-end"
                                    color="primary"
                                    @click="submit"
                            >
                                submit
                            </v-btn>
                            <v-btn class="float-end"
                                   @click="clear">
                                clear
                            </v-btn>
                        </form>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, email, sameAs} from 'vuelidate/lib/validators'

    export default {
        name: 'create_admin',
        data: () => ({
            menu: false,
            model: {
                name: '',
                email: '',
                email_confirmation: '',
                password: '',
                password_confirmation: '',
            },
            loading: false,
            hasClientErrors: false,
            hasServerErrors: false,
            serverErrors: {},
            submitAttempt: false,

        }),
        mixins: [validationMixin],
        validations: {
            model: {
                name: {
                    required,
                },
                email: {
                    required, email
                },
                email_confirmation: {
                    required,
                    sameAsEmail: sameAs('email'),
                },
                password: {
                    required
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs('password'),
                },
            }
        },
        watch: {},
        computed: {
            nameErrors() {
                const errors = []
                if (!this.$v.model.name.$dirty) return errors;
                !this.$v.model.name.required && errors.push('Name is required.');
                return errors
            },
            emailErrors() {
                const errors = []
                if (!this.$v.model.email.$dirty) return errors;
                !this.$v.model.email.email && errors.push('Must be valid e-mail')
                !this.$v.model.email.required && errors.push('E-mail is required')
                return errors
            },
            emailConfirmationErrors() {
                const errors = []
                if (!this.$v.model.email_confirmation.$dirty) return errors;
                !this.$v.model.email_confirmation.sameAsEmail && errors.push('E-mails must match')
                return errors
            },
            passwordErrors() {
                const errors = []
                if (!this.$v.model.password.$dirty) return errors
                !this.$v.model.password.required && errors.push('Password is required')
                return errors
            },
            passwordConfirmationErrors() {
                const errors = []
                if (!this.$v.model.password_confirmation.$dirty) return errors;
                !this.$v.model.password_confirmation.sameAsPassword && errors.push('Passwords must match')
                return errors
            },
        },
        methods: {
            submit() {
                this.submitAttempt = true;
                this.hasClientErrors = false;
                this.hasServerErrors = false;
                this.serverErrors = {};
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    const data = {
                        name: this.model.name,
                        email: this.model.email,
                        email_confirmation: this.model.email_confirmation,
                        password: this.model.password,
                        password_confirmation: this.model.password_confirmation,
                        type: 'A'
                    };

                    this.axios.post(process.env.VUE_APP_API_URL + "/admin", data)
                        .then(response => {
                            console.log(response.data);
                        }).catch(error => {
                            this.serverErrors = error.response.data.errors;
                            this.hasServerErrors = true;
                        }
                    );
                } else {
                    this.hasClientErrors = true;
                }
            },
            clear() {
                this.$v.$reset();
                this.name = '';
                this.email = '';
                this.email_confirmation = '';
                this.password = '';
                this.password_confirmation = '';
            },
        },
        created() {
            this.loading = true

        },
    }
</script>
