<template>
  <v-navigation-drawer
          v-model="drawer"
          app
          clipped
  >
    <v-list dense>

      <v-list-item to="/dashboard/resellers">
        <v-list-item-action >
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Resellers
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/customers">
        <v-list-item-action >
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Customers
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/wholesalers">
        <v-list-item-action >
          <v-icon>mdi-office-building</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Wholesalers
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/comissions">
        <v-list-item-action >
          <v-icon>mdi-chart-pie</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Comissions
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/admins">
        <v-list-item-action >
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Admins
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/support">
        <v-list-item-action >
          <v-icon>mdi-point-of-sale</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Support of Sale
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/licenses">
        <v-list-item-action >
          <v-icon>mdi-barcode</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            OfficeSuite Licenses
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-divider
        ></v-divider>
      </v-list-item>


      <v-list-item @click="downloadUserData">
        <v-list-item-action >
          <v-icon>mdi-file-excel</v-icon>
        </v-list-item-action>
        <v-list-item-content>
         <v-list-item-title>
            Export user Data
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="downloadLicensesData">
        <v-list-item-action >
          <v-icon>mdi-file-excel</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Export licenses Data
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>


    </v-list>

  </v-navigation-drawer>
</template>

<script>

const moment = require('moment');

export default {
  data: () => ({
    drawer: true,
    items: [
      { icon: 'trending_up', text: 'Most Popular' },
      { icon: 'subscriptions', text: 'Subscriptions' },
      { icon: 'history', text: 'History' },
      { icon: 'featured_play_list', text: 'Playlists' },
      { icon: 'watch_later', text: 'Watch Later' },
    ],
    items2: [
      { picture: 28, text: 'Joseph' },
      { picture: 38, text: 'Apple' },
      { picture: 48, text: 'Xbox Ahoy' },
      { picture: 58, text: 'Nokia' },
      { picture: 78, text: 'MKBHD' },
    ],
  }),
  methods: {
    downloadUserData() {
      this.axios.get(process.env.VUE_APP_API_URL + "/export/userdata", {
        headers: {
                'Content-Disposition': "attachment; filename=users.xlsx",
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      })
          .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'users.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
          }).catch(error => {
              console.log(error)
          }
      );
    
    },
    downloadLicensesData() {
      this.axios.get(process.env.VUE_APP_API_URL + "/export/licenses-data", {
        headers: {
          'Content-Disposition': "attachment; filename=users.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'officesuite_licenses_' + moment().format("DD_MM_YYYY_HH_mm_ss") + '.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            console.log(error)
          }
      );

    }
  },
  computed:{
  },
  created() {
    window.getApp.$on("APP_DRAWER_TOGGLED", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
