<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app-bar
            app
            clipped-left
            color="blue"
            dense
    >
        <v-app-bar-nav-icon @click="handleDrawerToggle" class="white--text"></v-app-bar-nav-icon>
        <v-toolbar-title class="mr-12 align-center">
            <span class="title white--text">RESELLERS</span>
        </v-toolbar-title>
        <div class="flex-grow-1"></div>

        <v-btn icon @click="logout">
            <v-icon class="white--text">mdi-logout-variant</v-icon>
        </v-btn>
        <v-menu
                left
                bottom
                :close-on-content-click="false"
        >
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" dark>
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list >
                <v-list-item>
                    <v-list-item-title>
                        <v-switch
                                label="Dark Theme"
                                color="cyan"
                                class="d-flex pl-3"
                                v-model="$vuetify.theme.dark"
                        ></v-switch>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </v-app-bar>
</template>

<script>
    export default {

        methods: {
            handleDrawerToggle() {
                window.getApp.$emit("APP_DRAWER_TOGGLED");
            },
            logout() {
              this.axios.get(process.env.VUE_APP_API_URL + "/admins/logout")
                  .then(() => {
                    this.$store.commit('setSessionToken', null);
                    this.$store.commit('setAuthenticated', false);
                    window.getApp.$router.push({name: 'login'})
                    return true;
                  }).catch(() => {

                return false;
              });

            },
        }
    };
</script>
