<template>
  <div>
    <v-card class="pa-4"
            outlined
            tile>
      <h3> Support of Sale </h3>
    </v-card>
    <hr>
    <v-card class="pa-4"
            outlined
            tile>
      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs6 md3 lg2 class="pb-0">
            <v-btn color="primary" @click="openDialog()">NEW</v-btn>
          </v-flex>
          <v-spacer></v-spacer>

          <v-flex xs12>
            <v-data-table :headers="headers"
                          :items="items"
                          :loading="loading"
                          class="elevation-1" loading-text="Loading...">

              <template v-slot:item.actions="{ item }">
                <v-btn
                    tile
                    small
                    color="success"
                    class="mr-2"
                    @click="downloadFile(item.id, item.name)"
                >
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
                <v-btn
                    tile
                    small
                    color="error"
                    @click="deleteFile(item.id)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        width="500"
    >

      <v-card>
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          Add File
        </v-toolbar>
        <v-card-text class="pt-8">
          <v-text-field
              v-model="model.name"
              :error-messages="nameErrors"
              label="Name"
              required
              @input="$v.model.name.$touch()"
              @blur="$v.model.name.$touch()"
              outlined
              dense
          ></v-text-field>

          <v-file-input
              v-model="model.file"
              color="deep-purple accent-4"
              :error-messages="fileErrors"
              label="File"
              placeholder="Select your file"
              prepend-icon=""
              append-icon="mdi-paperclip"
              @input="$v.model.file.$touch()"
              @blur="$v.model.file.$touch()"
              outlined
              dense
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="primary"
              class="float-end"

              @click="createFile"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

const Swal = require('sweetalert2')
export default {
  name: 'Support',

  data: () => ({
    menu: false,
    dialog: false,
    rowsPerPageItems: [5, 10, 20],
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
        sortable: true
      },
      {
        text: 'Actions',
        align: 'right',
        value: 'actions',
        width: 200,
      },
    ],
    model: {
      name: '',
      file: null,
    },
    items: [],
    loading: false,
    serverErrorCode: 0,
    serverErrorMessage: '',
    success: false,

  }),
  mixins: [validationMixin],
  validations: {
    model: {
      name: {
        required,
      },
      file: {
        required
      },
    }
  },
  watch: {},
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.model.name.$dirty) return errors;
      !this.$v.model.name.required && errors.push('Name is required.');
      return errors
    },
    fileErrors() {
      const errors = [];
      if (!this.$v.model.file.$dirty) return errors;
      !this.$v.model.file.required && errors.push('File is required.');
      return errors
    },
  },
  methods: {
    openDialog(){
      this.dialog = true;
      this.$v.$reset();
      this.model.name = '';
      this.model.file = null;
    },
    getFiles() {
      this.axios.get(process.env.VUE_APP_API_URL + "/files")
          .then(response => {
            this.loading = false;
            this.items = response.data.files
          }).catch(error => {
        this.loading = false;
        console.log(error.response)
      });
    },
    createFile() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(this.model.file);
        // const data = {
        //   name: this.model.name,
        //   file: this.model.file.file,
        // };
        let formData = new FormData();
        formData.append("file", this.model.file);
        formData.append("name", this.model.name);
        this.axios.post(process.env.VUE_APP_API_URL + "/upload-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }).then(() => {
          Swal.fire({
            text: 'File Created!',
            icon: 'success',
            confirmButtonColor: '#77BB22',
            confirmButtonText: 'Close',
            showCloseButton: true
          })
          this.dialog = false;
          this.getFiles();
        }).catch(error => {
              Swal.fire({
                text: 'Error creating File! (' + error.response.data.errors + ')',
                icon: 'error',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
              this.serverErrors = error.response.data.errors;
              this.hasServerErrors = true;
            }
        );
      }
    },
    deleteFile(id) {
      Swal.fire({
        text: 'You sure you want to delete this file?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#77BB22',
        cancelButtonText: 'Yes',
        confirmButtonText: 'No',
        showCloseButton: true
      }).then((result) => {
        if (!result.isConfirmed) {

          this.axios.delete(process.env.VUE_APP_API_URL + "/delete-file/" + id).then(() => {
            Swal.fire({
              text: 'File Deleted!',
              icon: 'success',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
            this.getFiles();
          }).catch(error => {
                Swal.fire({
                  text: 'Error deleting File! (' + error.response.data.errors + ')',
                  icon: 'error',
                  confirmButtonColor: '#77BB22',
                  confirmButtonText: 'Close',
                  showCloseButton: true
                })
                this.serverErrors = error.response.data.errors;
                this.hasServerErrors = true;
              }
          );
        }
      })
    },
    downloadFile(id, name) {

      this.axios.get(process.env.VUE_APP_API_URL + "/file/download/" + id, {
        headers: {
          'Content-Disposition': "attachment; filename=users.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer'
      })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
          }).catch(error => {
            console.log(error)
          }
      );

    }
  },
  created() {
    this.loading = true
    this.getFiles()
  },
}
</script>
