<template>
    <div>
        <v-card class="pa-4"
                outlined
                tile>
            <h3> Admins </h3>
        </v-card>
        <hr>
        <v-card class="pa-4"
                outlined
                tile>
            <v-container grid-list-lg>
                <v-layout row wrap>
                    <v-flex xs6 md3 lg2 class="pb-0">
                        <v-btn color="primary" @click="$router.push({name: 'create_admin'})">NEW</v-btn>
                    </v-flex>
                    <v-spacer></v-spacer>
                    <v-flex xs12 md5 lg4 class="pb-0">
                        <v-text-field
                                v-model.trim="search"
                                append-icon="search"
                                label="Search"
                                color="blue"
                                dense
                                clearable
                        ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                        <v-data-table :headers="headers"
                                      :items="items"
                                      :loading="loading"
                                      :options.sync="options"
                                      :search="search"
                                      :server-items-length="total"
                                      :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Items per page:'}"
                                      class="elevation-1" loading-text="Loading...">


                            <template v-slot:item.action="{ }">
                                <v-btn small color="primary" dark tile outlined>
                                    <v-icon left dark>
                                        mdi-cash
                                    </v-icon>
                                    Pay
                                </v-btn>
                            </template>

                        </v-data-table>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </div>
</template>

<script>

    export default {
        name: 'Admins',

        data: () => ({
            menu: false,
            total: 0,
            options: {
                itemsPerPage: 10,
            },
            search: '',
            rowsPerPageItems: [5, 10, 15, 25, 50, -1],
            headers: [
                {
                    text: 'Name',
                    align: 'left',
                    value: 'name',
                    sortable: true
                },
                {
                    text: 'Email',
                    align: 'left',
                    value: 'email',
                    sortable: true
                },
                {
                    text: 'Type',
                    align: 'left',
                    value: 'type',
                    sortable: true
                },
            ],
            items: [],
            loading: false,
            serverErrorCode: 0,
            serverErrorMessage: '',
            success: false,

        }),
        watch: {
            options: {
                handler() {
                    this.getAdmins()
                },
                deep: true,
            },
        },
        methods: {
            getAdmins() {
                this.axios.get(process.env.VUE_APP_API_URL + "/admins?items_per_page=" + this.options.itemsPerPage + "&page=" + this.options.page)
                    .then(response => {
                        this.loading = false;
                        this.items = response.data.data
                        this.total = response.data.meta.total
                    }).catch(error => {
                    this.loading = false;
                    console.log(error.response)
                });
            }
        },
        created() {
            this.loading = true
            this.getAdmins()
        },
    }
</script>
