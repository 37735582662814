var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","tile":""}},[_c('h3',[_vm._v(" Customers ")])]),_c('hr'),_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","tile":""}},[_c('v-container',{attrs:{"grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pb-0",attrs:{"xs6":"","md3":"","lg2":""}}),_c('v-spacer'),_c('v-flex',{staticClass:"pb-0",attrs:{"xs12":"","md5":"","lg4":""}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","color":"blue","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1 nowrap",attrs:{"headers":_vm.headers,"items":_vm.customers,"server-items-length":_vm.total,"loading":_vm.tableLoading,"options":_vm.options,"search":_vm.search,"footer-props":{
                                    itemsPerPageOptions: _vm.rowsPerPageItems,
                                    itemsPerPageText: 'Items per page:'},"loading-text":"Loading..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                                    var item = ref.item;
return [(item.status !== '-')?_c('v-chip',{attrs:{"color":item.status === '1' ?'green':'red',"dark":"","label":""}},[_vm._v(" "+_vm._s(item.status === "1" ? 'Active':'Inactive')+" ")]):_c('div',[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.start_date",fn:function(ref){
                                    var item = ref.item;
return [(item.start_date === '-')?_c('div',[_vm._v(_vm._s(item.start_date))]):_c('div',[_vm._v(_vm._s(_vm.formatDate(item.start_date)))])]}},{key:"item.end_date",fn:function(ref){
                                    var item = ref.item;
return [(item.end_date === '-')?_c('div',[_vm._v(_vm._s(item.end_date))]):_c('div',[_vm._v(_vm._s(_vm.formatDate(item.end_date)))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }