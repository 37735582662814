<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="pa-4"
            outlined
            tile>

      <h3> Customers </h3>

    </v-card>
    <hr>
    <v-card class="pa-4"
            outlined
            tile>
      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs6 md3 lg2 class="pb-0">
          </v-flex>

          <v-spacer></v-spacer>
          <v-flex xs12 md5 lg4 class="pb-0">
            <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                color="blue"
                dense
                clearable
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-data-table :headers="headers"
                          :items="customers"
                          :server-items-length="total"
                          :loading="tableLoading"
                          :options.sync="options"
                          :search="search"
                          :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Items per page:'}"
                          class="elevation-1 nowrap" loading-text="Loading...">

              <template v-slot:item.status="{ item }">
                <v-chip
                    v-if="item.status !== '-'"
                    :color="item.status === '1' ?'green':'red'"
                    dark
                    label>
                  {{ item.status === "1" ? 'Active':'Inactive'  }}
                </v-chip>
                <div v-else>  {{ item.status }} </div>
              </template>

              <template v-slot:item.start_date="{ item }">
                <div v-if="item.start_date === '-'">{{item.start_date }}</div>
                <div v-else>{{ formatDate(item.start_date) }}</div>
              </template>
              <template v-slot:item.end_date="{ item }">
                <div v-if="item.end_date === '-'">{{item.end_date }}</div>
                <div v-else>{{ formatDate(item.end_date) }}</div>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
// const Swal = require('sweetalert2')
const moment = require('moment');
export default {
  name: 'Customers',

  data: () => ({
    today: moment().format("YYYY-MM-DD"),
    total: 0,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    search: '',
    rowsPerPageItems: [5, 10, 20],
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Adress',
        align: 'center',
        value: 'address',
        sortable: true,
      },
      {
        text: 'Phone',
        align: 'center',
        value: 'phone',
        sortable: true,
      },
      {
        text: 'Email',
        align: 'center',
        value: 'email',
        sortable: true,
      },
      {
        text: 'Product',
        align: 'center',
        value: 'product',
        sortable: false,
      },
      {
        text: 'Status',
        align: 'center',
        value: 'status',
        sortable: false,
      },
      {
        text: 'Start Date',
        align: 'center',
        value: 'start_date',
        sortable: false,
      },
      {
        text: 'End Date',
        align: 'center',
        value: 'end_date',
        sortable: false,
      },
    ],
    customers: [],
    status: '',
    loading: false,
    tableLoading: false,
    serverErrorCode: 0,
    serverErrorMessage: '',
    success: false,
  }),
  watch: {
    options: {
      handler() {
        this.getCustomers()
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      this.getCustomers()
    },
  },
  computed: {
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getColor(val) {
      if (val > 74) {
        return 'green'
      }
      return val > 49 ? 'orange' : 'red'
    },
    getCustomers() {
      this.tableLoading = true;
      const sortBy = this.options.sortBy && this.options.sortBy.length ? '&order=' +  this.options.sortBy[0] +  '&desc=' + this.options.sortDesc[0] : '';
      this.axios.get(process.env.VUE_APP_API_URL + "/users?name=" + this.search + "&items_per_page=" + this.options.itemsPerPage + "&page=" + this.options.page +sortBy)
          .then(response => {
            this.total = response.data.meta.total
            this.customers = response.data.data;
            this.tableLoading = false;
            console.log("Customers DATA:", response.data);
          }).catch(error => {
            this.tableLoading = false;
            console.log(error.response)
          }
      );
    },

  },
  mounted() {
    this.getCustomers()
    console.log(process.env);
  }

}
</script>

<style>
.nowrap > .v-data-table__wrapper > table > tbody > tr > td {
  white-space: nowrap !important;
}
</style>