var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","tile":""}},[_c('h3',[_vm._v(" OfficeSuite Licenses ")])]),_c('hr'),_c('v-card',{staticClass:"pa-4",attrs:{"outlined":"","tile":""}},[_c('v-container',{attrs:{"grid-list-lg":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},_vm._l((_vm.licensesCountOrder.length),function(index){return _c('v-flex',{key:index,attrs:{"xs12":"","md3":""}},[(_vm.licensesCountComputed.some(function (l) { return l.status === _vm.licensesCountOrder[index - 1]; }))?_c('v-card',{staticClass:"mx-auto"},[_c('v-card-text',[_c('p',{staticClass:"text-center text-h5 mb-0",style:({ color: _vm.countColor(_vm.licensesCountComputed.find(function (l) { return l.status === _vm.licensesCountOrder[index - 1]; }).status)})},[_vm._v(" "+_vm._s(_vm.licensesCountComputed.find(function (l) { return l.status === _vm.licensesCountOrder[index - 1]; }).status))]),_c('p',{staticClass:"text-center text-h3 mb-0",style:({ color: _vm.countColor(_vm.licensesCountComputed.find(function (l) { return l.status === _vm.licensesCountOrder[index - 1]; }).status)})},[_vm._v(_vm._s(_vm.licensesCountComputed.find(function (l) { return l.status === _vm.licensesCountOrder[index - 1]; }).count))])])],1):_vm._e()],1)}),1),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pb-0",attrs:{"xs6":"","md3":"","lg2":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDialog()}}},[_vm._v("Upload Licenses")])],1),_c('v-spacer'),_c('v-flex',{attrs:{"xs12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options,"footer-props":{
                          itemsPerPageOptions: _vm.rowsPerPageItems,
                          itemsPerPageText: 'Items per page:'
                        },"loading-text":"Loading..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
                        var item = ref.item;
return [_c('v-chip',{staticStyle:{"text-transform":"capitalize"},attrs:{"color":item.type === 'business' ? 'blue' : item.type === 'famille' ? 'teal' : 'orange',"dark":""}},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status === 1 ? 'indigo' : item.status === 2 ? 'green' : '',"dark":"","label":""}},[_vm._v(" "+_vm._s(item.status === 1 ? 'Available' : item.status === 2 ? 'Active' : 'Inactive')+" ")])]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"text-h5",attrs:{"color":"primary","dark":""}},[_vm._v(" Add licenses ")]),_c('v-card-text',{staticClass:"pt-8"},[_c('v-file-input',{attrs:{"color":"deep-purple accent-4","error-messages":_vm.fileErrors,"label":"File","placeholder":"Select your file","prepend-icon":"","append-icon":"mdi-paperclip","outlined":"","dense":""},on:{"input":function($event){return _vm.$v.model.file.$touch()},"blur":function($event){return _vm.$v.model.file.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var index = ref.index;
                        var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.model.file),callback:function ($$v) {_vm.$set(_vm.model, "file", $$v)},expression:"model.file"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{staticClass:"float-end",attrs:{"color":"primary"},on:{"click":function($event){return _vm.uploadLicenses()}}},[_vm._v(" Upload ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }