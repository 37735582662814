<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="pa-4"
            outlined
            tile>

      <h3> Resellers </h3>

    </v-card>
    <hr>
    <v-card class="pa-4"
            outlined
            tile>
      <v-container grid-list-lg>
        <v-layout row wrap>
          <v-flex xs6 md3 lg2 class="pb-0">
            <v-select
                :items="statusList"
                item-text="text"
                item-value="value"
                v-model="status"
                label="Status"
                color="blue"
                multiple
            ></v-select>
          </v-flex>

          <v-spacer></v-spacer>
          <v-flex xs12 md5 lg4 class="pb-0">
            <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                color="blue"
                dense
                clearable
            ></v-text-field>
          </v-flex>

          <v-flex xs12>
            <v-data-table :headers="headers"
                          :items="resellers"
                          :server-items-length="total"
                          :loading="tableLoading"
                          :options.sync="options"
                          :search="search"
                          :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Items per page:'}"
                          class="elevation-1 nowrap" loading-text="Loading...">

              <template v-slot:item.name="{ item }">
                {{`${item.company} - `}}<strong>{{item.name}}</strong>
                <v-btn icon color="primary" @click="openInfoDialog(item)">
                  <v-icon dark>
                    mdi-information-variant
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:item.cupons="{ item }">
                <v-btn icon color="primary" @click="openCuponDialog(item)">
                  <v-icon dark>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:item.active_subs="{ item }">
                {{ item.active_subs }}
              </template>

              <template v-slot:item.inactive_subs="{ item }">
                {{ item.inactive_subs }}
              </template>

              <template v-slot:item.retention="{ item }">
                <v-chip
                    :color="getColor(resellerTotalRate(item))"
                    dark
                >
                  {{ resellerTotalRate(item) }}%
                </v-chip>
                <v-btn icon color="primary" @click="openSubscriptionsDialog(item)">
                  <v-icon dark>
                    mdi-information-variant
                  </v-icon>
                </v-btn>
              </template>

              <template v-slot:item.volume_subscription_sales="{item}">
                {{ item.volume_subscription_sales.toFixed(2) }} €
              </template>

              <template v-slot:item.total_paid_comissions="{item}">
                {{ item.total_paid_comissions.toFixed(2) }} €
              </template>

              <template v-slot:item.balance="{item}">
                {{ item.balance.toFixed(2) }} €
              </template>

              <template v-slot:item.last_payment="{ item }">

                {{ formatDate(item.last_payment) }}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                    :color="item.status === 'R'?'blue':item.status === 'B'?'red':'green'"
                    dark
                    label
                >
                  {{ item.status === 'R' ? 'Registered' : item.status === 'B' ? 'Blocked' : 'Active' }}
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn @click="payBalance(item)" class="mx-1" small color="primary" dark tile outlined
                       v-if="item.status === 'A' && item.balance > 0">
                  <v-icon left dark>
                    mdi-cash
                  </v-icon>
                  Pay
                </v-btn>
                <v-btn small color="green" class="mx-1" dark tile outlined v-if="item.status === 'R'"
                       @click="changeResellerStatus(item.id,'A')">Activate
                </v-btn>
                <v-btn small color="red" class="mx-1" dark tile outlined v-if="item.status === 'A'"
                       @click="changeResellerStatus(item.id,'B')">Block
                </v-btn>
                <v-btn small color="teal" class="mx-1" dark tile outlined v-if="item.status === 'B'"
                       @click="changeResellerStatus(item.id,'A')">Unblock
                </v-btn>
              </template>

            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-dialog
        v-model="cuponDialog"
        scrollable
        persistent
        width="500"
    >

      <v-card v-if="selectedItem">
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          {{ selectedItem.name }} Cupons
        </v-toolbar>
        <v-card-text class="pt-2">
          <v-btn default color="secondary" dark tile outlined class="my-2 mx-auto"
                 @click="cuponModel.id= null;openAddCupon()">
            <v-icon left dark>
              mdi-plus
            </v-icon>
            Create
          </v-btn>
          <v-btn default color="primary" dark tile outlined class="my-2 mx-auto float-right"
                 @click="sendCuponsEmail()">
            <v-icon left dark>
              mdi-send
            </v-icon>
            Send Cupons
          </v-btn>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center">
                  Code
                </th>
                <th class="text-center">
                  Type
                </th>
                <th class="text-center">
                  Trial Days
                </th>
                <th class="text-center">
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(c, index) in selectedCupons" :key="index" class="text-center">
                <td><strong>{{ c.code }}</strong></td>
                <td>{{ c.comission_type }}</td>
                <td>{{ c.days_trial }}</td>
                <td>
                  <v-btn icon color="primary" @click="openEditCuponDialog(c)">
                    <v-icon dark small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn icon color="red" @click="deleteCupon(c.id)">
                    <v-icon dark small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="cuponDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="subscriptionsDialog"
        scrollable
        width="700"
    >

      <v-card v-if="selectedItem">
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          {{ selectedItem.name }} Subscriptions Details
        </v-toolbar>

        <v-card-text class="pt-5">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Active
                </th>
                <th class="text-left">
                  Inactive
                </th>
                <th class="text-left">
                  Retention Rate
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in selectedComissionStats"
                  :key="index"
              >
                <td><strong>{{ item.name }}</strong></td>
                <td>{{ item.active_comissions }}</td>
                <td>{{ item.inactive_comissions }}</td>
                <td>
                  <v-chip
                      :color="getColor(item.rate)"
                      dark
                  >
                    {{ item.rate }}%
                  </v-chip>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td><strong>Total</strong></td>
                <td>
                  <v-chip
                      :color="getColor(resellerTotalRate(selectedItem))"
                      dark
                  >
                    {{
                      resellerTotalRate(selectedItem)
                    }}%
                  </v-chip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="subscriptionsDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="addCuponDialog"
        persistent
        scrollable
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Cupon</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <h3 light>Cupon Fields</h3>
            <v-divider/>
            <br/>
            <v-row>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    label="Code"
                    required
                    :disabled="cuponModel.id != null"
                    v-model="cuponModel.code"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-select
                    :items="comissionTypes"
                    item-text="text"
                    item-value="value"
                    label="Comission Type"
                    required
                    v-model="cuponModel.comission_type"
                ></v-select>

              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >

                <v-menu
                    ref="menu1"
                    v-model="experationDateShow"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="cuponModel.expiration"
                        label="Expiration Date"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        readonly
                        clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="cuponModel.expiration"
                      no-title
                      :min="today"
                      @input="experationDateShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    label="Days of Trial"
                    type="number"
                    v-model="cuponModel.days_trial"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <br/>
            <br/>
            <h3 light>Seller Fields</h3>
            <v-divider/>
            <br/>
      
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="First Name" v-model="cuponModel.seller_name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
              <v-text-field label="Surname" v-model="cuponModel.seller_lastname" ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
              <v-text-field label="Shop" v-model="cuponModel.shop" ></v-text-field>
              </v-col>
            </v-row>

            <br/>
            <h3 light>Comissions Fields (€)</h3>
            <v-divider/>
            <br/>
             <v-row>
              <v-col cols="12" sm="6" v-for="product in subscriptionTypes" :key="product.value">
              <v-text-field :label="`${product.text} Comission`" type="number" v-model="cuponModel.seller_comission[product.value]" ></v-text-field>
              </v-col>
            </v-row>
            <v-divider/>
            <br/>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Notification Email" v-model="cuponModel.notify_email"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="addCuponDialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="createCupon"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="infoDialog"
        scrollable
        width="700"
    >

      <v-card v-if="selectedItem">
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          {{ selectedItem.name }} Details
        </v-toolbar>

        <v-card-text class="pt-5">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <td><strong>Name</strong></td>
                <td>{{ selectedItem.name }}</td>
              </tr>
              <tr>
                <td><strong>Company</strong></td>
                <td>{{ selectedItem.company }}</td>
              </tr>
              <tr>
                <td><strong>Siren</strong></td>
                <td>{{ selectedItem.siren }}</td>
              </tr>
              <tr>
                <td><strong>Phone</strong></td>
                <td>{{ selectedItem.phone }}</td>
              </tr>
              <tr>
                <td><strong>E-mail</strong></td>
                <td>{{ selectedItem.email }}</td>
              </tr>
              <tr>
                <td><strong>Sales Portal</strong></td>
                <td><a :href="salesPortalURL" target="_blank">{{ salesPortalURL }}</a></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="infoDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const Swal = require('sweetalert2')
const moment = require('moment');
// import products from '../../helpers/products.json'
export default {
  name: 'WholeSalers',

  data: () => ({
    // productsList: products,
    experationDateShow: false,
    today: moment().format("YYYY-MM-DD"),
    total: 0,
    panel: false,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    search: '',
    rowsPerPageItems: [5, 10, 20],
    comissionTypes: [
      {value: 'TYPE_A', text: 'Type A'},
      {value: 'TYPE_B', text: 'Type B'},
      {value: 'TYPE_C', text: 'Type C'},
      {value: 'TYPE_SELECTRA', text: 'Type Selectra'},
    ],
    subscriptionTypes: [
      {value: 'FAMILY_1', text: 'Family 1 Device'},
      {value: 'FAMILY_2', text: 'Family 3 Devices'},
      {value: 'FAMILY_3', text: 'Family 5 Devices'},
      {value: 'FAMILY_4', text: 'Family 10 Devices'},
      {value: 'PROFESSIONAL_1', text: 'Professional 5 Devices'},
      {value: 'PROFESSIONAL_2', text: 'Professional 10 Devices'},
      {value: 'PROFESSIONAL_3', text: 'Professional 20 Devices'},
    ],
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
        sortable: true,
      },
      {
        text: 'Cupons',
        align: 'left',
        value: 'cupons',
        sortable: false,
      },
      {
        text: 'Active Subscriptions',
        align: 'left',
        value: 'active_comissions',
        sortable: true,
      },
      {
        text: 'Inactive Subscriptions',
        align: 'left',
        value: 'inactive_comissions',
        sortable: true,
      },
      {
        text: 'Retention rate',
        align: 'left',
        value: 'retention',
        sortable: false,
      },
      {
        text: 'Current volume',
        align: 'left',
        value: 'volume_subscription_sales',
        sortable: false,
      },
      {
        text: 'Total received',
        align: 'left',
        value: 'total_paid_comissions',
        sortable: true,
      },
      {
        text: 'Last payment date',
        align: 'left',
        value: 'last_payment',
        sortable: true,
      },
      {
        text: 'Balance',
        align: 'left',
        value: 'balance',
        sortable: true,
      },
      {
        text: 'Status',
        align: 'left',
        value: 'status',
        sortable: true,
      },
      {
        text: 'Actions',
        align: 'left',
        value: 'action',
      },
    ],
    cuponModel: {
      id: null,
      code: '',
      days_trial: 0,
      expiration: null,
      comission_type: '',
      seller_name: null, 
      seller_lastname: null, 
      shop: null, 
      seller_comission: {},
      notify_email: null,
    },
    resellers: [],
    statusList: [
      {value: 'R', text: 'Registered'},
      {value: 'A', text: 'Active'},
      {value: 'B', text: 'Blocked'}
    ],
    status: '',
    loading: false,
    tableLoading: false,
    serverErrorCode: 0,
    serverErrorMessage: '',
    success: false,
    cuponDialog: false,
    subscriptionsDialog: false,
    infoDialog: false,
    addCuponDialog: false,
    selectedItem: null,
    selectedComissionStats: null,
    selectedCupons: null,
  }),
  watch: {
    options: {
      handler() {
        console.log(this.options);
        this.getResellers()
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      this.getResellers()
    },
    status() {
      this.options.page = 1;
      this.getResellers()
    },
  },
  computed: {
    exportUserDataURL() {
      return process.env.VUE_APP_API_URL + '/export/userdata/?sessionToken=' + this.$store.state.sessionToken
    },
    salesPortalURL(){
      return process.env.VUE_APP_RESELLER_URL + '/sales-portal/' + this.selectedItem.id
    },
  },
  methods: {
    sendCuponsEmail() {
      this.loading = true;
      const data = {
        reseller_id: this.selectedItem.id,
      };

      this.axios.post(process.env.VUE_APP_API_URL + "/reseller/cupons/send", data)
          .then(() => {

            this.loading = false;
            Swal.fire({
              text: 'Cupons sent to reseller!',
              icon: 'success',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }).catch(() => {
            this.loading = false;
            Swal.fire({
              text: 'Error sending email!',
              icon: 'error',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            });

          }
      );
    },
    payBalance(reseller) {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/payoutReseller/" + reseller.id)
          .then((response) => {
            reseller.balance = response.data.data.balance
            reseller.total_paid_comissions = response.data.data.total_paid_comissions
            reseller.last_balance_paid = response.data.data.last_balance_paid
            this.loading = false;
            Swal.fire({
              text: 'Payment completed!',
              icon: 'success',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }).catch(error => {
            this.loading = false;
            Swal.fire({
              text: error.response.data.msg,
              icon: 'error',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }
      );
    },
    totalRate(resellers) {
      let total = 0
      resellers.map(resseler => {
        total += resseler.rate
      })

      return total ? total / resellers.length : total
    },
    resellerTotalRate(reseller) {
      return ((reseller.active_comissions + reseller.inactive_comissions) === 0 ? 0 : (reseller.active_comissions * 100) / (reseller.active_comissions + reseller.inactive_comissions)).toFixed(2);
    },
    changeResellerStatus(id, status) {
      this.loading = true;
      const data = {
        reseller_id: id,
        status: status,
      };

      this.axios.post(process.env.VUE_APP_API_URL + "/reseller/update/status", data)
          .then(response => {
            let index = this.resellers.indexOf(this.resellers.find(r => r.id === id));
            this.resellers[index].status = status;
            if (response.data.connected_data.error) {
              Swal.fire({
                text: 'Reseller status updated but failed to send email to reseller!',
                icon: 'warning',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
            } else {
              Swal.fire({
                text: 'Reseller status updated!',
                icon: 'success',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
            }
            this.loading = false;
          }).catch(() => {
            this.loading = false;
            Swal.fire({
              text: 'Error updating status!',
              icon: 'error',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })

          }
      );
    },
    openAddCupon() {
      this.cuponModel.id = null,
      this.cuponModel.code = '',
      this.cuponModel.days_trial = 0,
      this.cuponModel.expiration = null,
      this.cuponModel.comission_type = '',
      this.cuponModel.seller_name = null, 
      this.cuponModel.seller_lastname = null, 
      this.cuponModel.shop = null, 
      this.cuponModel.seller_comission = {},
      this.cuponModel.notify_email = null,

      this.addCuponDialog = true;
    },
    openEditCuponDialog(cupon) {
      this.cuponModel.id = cupon.id;
      this.cuponModel.code = cupon.code;
      this.cuponModel.days_trial = cupon.days_trial;
      this.cuponModel.expiration = cupon.expiration_date ? moment(cupon.expiration_date).format("YYYY-MM-DD") : null;
      this.cuponModel.comission_type = cupon.comission_type;
      this.cuponModel.seller_name = cupon.seller_name, 
      this.cuponModel.seller_lastname = cupon.seller_lastname, 
      this.cuponModel.shop = cupon.shop, 
      this.cuponModel.seller_comission = cupon.seller_comission ? cupon.seller_comission : {},
      this.addCuponDialog = true;
    },
    createCupon() {
      if (!this.cuponModel.id) {
        this.loading = true;
        const data = {
          reseller_id: this.selectedItem.id,
          code: this.cuponModel.code,
          days_trial: this.cuponModel.days_trial,
          expiration_date: this.cuponModel.expiration,
          comission_type: this.cuponModel.comission_type,
          seller_name: this.cuponModel.seller_name, 
          seller_lastname: this.cuponModel.seller_lastname, 
          shop: this.cuponModel.shop, 
          seller_comission: Object.keys(this.cuponModel.seller_comission).length ? JSON.stringify(this.cuponModel.seller_comission) : null,
          notify_email: this.cuponModel.notify_email
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/cupon/create", data)
            .then(response => {
              this.selectedCupons.push(response.data.cupon);
              console.log(response.data);
              this.loading = false;
              Swal.fire({
                text: 'Cupon Created!',
                icon: 'success',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
              this.addCuponDialog = false;

            }).catch(() => {
              this.loading = false;
              Swal.fire({
                text: 'Error creating cupon!',
                icon: 'error',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
            }
        );
      } else {
        const data = {
          cupon_id: this.cuponModel.id,
          code: this.cuponModel.code,
          days_trial: this.cuponModel.days_trial,
          expiration_date: this.cuponModel.expiration,
          comission_type: this.cuponModel.comission_type,
          seller_name: this.cuponModel.seller_name, 
          seller_lastname: this.cuponModel.seller_lastname, 
          shop: this.cuponModel.shop, 
          seller_comission: Object.keys(this.cuponModel.seller_comission).length ? JSON.stringify(this.cuponModel.seller_comission) : null,
          notify_email: this.cuponModel.notify_email
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/cupon/update", data)
            .then(() => {
              let index = this.selectedCupons.indexOf(this.selectedCupons.find(c => c.id === this.cuponModel.id));
              this.selectedCupons[index].code = this.cuponModel.code;
              this.selectedCupons[index].days_trial = this.cuponModel.days_trial;
              this.selectedCupons[index].comission_type = this.cuponModel.comission_type;
              this.selectedCupons[index].seller_name = this.cuponModel.seller_name, 
              this.selectedCupons[index].seller_lastname = this.cuponModel.seller_lastname, 
              this.selectedCupons[index].shop = this.cuponModel.shop, 
              this.selectedCupons[index].seller_comission = this.cuponModel.seller_comission,
              this.loading = false;
              Swal.fire({
                text: 'Cupon Updated!',
                icon: 'success',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
              this.addCuponDialog = false;

            }).catch(() => {
              this.loading = false;
              Swal.fire({
                text: 'Error updating cupon!',
                icon: 'error',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
            }
        );
      }
    },
    deleteCupon(id) {
      this.loading = true;
      this.axios.post(process.env.VUE_APP_API_URL + "/cupon/delete/" + id)
          .then(response => {
            let index = this.selectedCupons.indexOf(this.selectedCupons.find(c => c.id === id));
            console.log(index);
            this.selectedCupons.splice(index, 1);
            console.log(response.data);
            this.loading = false;
            Swal.fire({
              text: 'Cupon Deleted!',
              icon: 'success',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }).catch(error => {
            console.log(error.response)
            this.loading = false;
            Swal.fire({
              text: 'Error deleting cupon!',
              icon: 'error',
              confirmButtonColor: '#77BB22',
              confirmButtonText: 'Close',
              showCloseButton: true
            })
          }
      );
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    openCuponDialog(item) {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/cupons/" + item.id)
          .then(response => {
            this.selectedCupons = response.data;
            (this.selectedCupons || []).map(c => {
              c.seller_comission = JSON.parse(c.seller_comission);
            })
            this.selectedItem = item;
            this.cuponDialog = true;
            this.loading = false;
          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );

    },
    openSubscriptionsDialog(item) {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/comissionStats/" + item.id)
          .then(response => {
            this.selectedComissionStats = response.data.data
            this.selectedItem = item;
            this.subscriptionsDialog = true;
            this.loading = false;
            console.log("Commissions Stats", response.data.data)
          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );

    },
    openInfoDialog(item) {
      console.log(item);
      this.selectedItem = item;
      this.infoDialog = true;
    },
    getColor(val) {
      if (val > 74) {
        return 'green'
      }
      return val > 49 ? 'orange' : 'red'
    },
    getResellers() {
      this.tableLoading = true;
      const sortBy = this.options.sortBy && this.options.sortBy.length ? '&order=' +  this.options.sortBy[0] +  '&desc=' + this.options.sortDesc[0] : '';
      this.axios.get(process.env.VUE_APP_API_URL + "/resellers?name=" + this.search + "&status=" + this.status + "&items_per_page="
          + this.options.itemsPerPage + "&page=" + this.options.page + sortBy)
          .then(response => {
            this.total = response.data.meta.total
            this.resellers = response.data.data;
            this.tableLoading = false;
            console.log("Resselers DATA:", response.data);
          }).catch(error => {
            this.tableLoading = false;
            console.log(error.response)
          }
      );
    },
    getResellerCupons(id) {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/cupons/" + id)
          .then(response => {
            this.selectedCupons = response.data;
            this.loading = false;
            console.log(response.data);
          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );
    },
  },
  mounted() {
    this.getResellers()
    console.log(process.env);
  }

}
</script>

<style>
.nowrap > .v-data-table__wrapper > table > tbody > tr > td {
  white-space: nowrap !important;
}
</style>