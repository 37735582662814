<template>
  <div>
    <v-card class="pa-4"
            outlined
            tile>
      <h3> OfficeSuite Licenses </h3>
    </v-card>
    <hr>
    <v-card class="pa-4"
            outlined
            tile>
      <v-container grid-list-lg>
        <v-layout row wrap justify-center>
          <v-flex xs12 md3 v-for="index in licensesCountOrder.length" :key="index">
            <v-card
                class="mx-auto" v-if="licensesCountComputed.some(l => l.status === licensesCountOrder[index - 1])">
              <v-card-text>
                <p class="text-center text-h5 mb-0" :style="{ color: countColor(licensesCountComputed.find(l => l.status === licensesCountOrder[index - 1]).status)}"> {{licensesCountComputed.find(l => l.status === licensesCountOrder[index - 1]).status}}</p>

                <p class="text-center text-h3 mb-0" :style="{ color: countColor(licensesCountComputed.find(l => l.status === licensesCountOrder[index - 1]).status)}">{{ licensesCountComputed.find(l => l.status === licensesCountOrder[index - 1]).count }}</p>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs6 md3 lg2 class="pb-0">
            <v-btn color="primary" @click="openDialog()">Upload Licenses</v-btn>
          </v-flex>
          <v-spacer></v-spacer>

          <v-flex xs12>
            <v-data-table :headers="headers"
                          :items="items"
                          :loading="loading"
                          :server-items-length="total"
                          :options.sync="options"
                          :footer-props="{
                            itemsPerPageOptions: rowsPerPageItems,
                            itemsPerPageText: 'Items per page:'
                          }"
                          class="elevation-1" loading-text="Loading...">

              <template v-slot:item.type="{ item }">
                <v-chip
                    :color="item.type === 'business' ? 'blue' : item.type === 'famille' ? 'teal' : 'orange'"
                    dark
                style="text-transform: capitalize">
                  {{ item.type }}
                </v-chip>
              </template>

              <template v-slot:item.status="{ item }">
                <v-chip
                    :color="item.status === 1 ? 'indigo' : item.status === 2 ? 'green' : ''"
                    dark
                    label>
                  {{ item.status === 1 ? 'Available' : item.status === 2 ? 'Active' : 'Inactive' }}
                </v-chip>
              </template>

            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-dialog
        v-model="dialog"
        scrollable
        persistent
        width="500"
    >

      <v-card>
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          Add licenses
        </v-toolbar>
        <v-card-text class="pt-8">
          <v-file-input
              v-model="model.file"
              color="deep-purple accent-4"
              :error-messages="fileErrors"
              label="File"
              placeholder="Select your file"
              prepend-icon=""
              append-icon="mdi-paperclip"
              @input="$v.model.file.$touch()"
              @blur="$v.model.file.$touch()"
              outlined
              dense
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
              color="primary"
              class="float-end"

              @click="uploadLicenses()"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

const Swal = require('sweetalert2')
export default {
  name: 'Licenses',

  data: () => ({
    menu: false,
    dialog: false,
    total: 0,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    rowsPerPageItems: [5, 10, 20],
    licensesCount: [],
    licensesCountOrder : ['Available', 'Active', 'Inactive'],
    headers: [
      {
        text: 'License',
        align: 'left',
        value: 'license',
        sortable: true
      },
      {
        text: 'Type',
        align: 'center',
        value: 'type',
        sortable: true
      },
      {
        text: 'Status',
        align: 'center',
        value: 'status',
        sortable: true
      },
    ],
    model: {
      file: null,
    },
    items: [],
    loading: false,
    serverErrorCode: 0,
    serverErrorMessage: '',
    success: false,

  }),
  mixins: [validationMixin],
  validations: {
    model: {
      file: {
        required
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getLicenses()
      },
      deep: true,
    },
  },
  computed: {
    fileErrors() {
      const errors = [];
      if (!this.$v.model.file.$dirty) return errors;
      !this.$v.model.file.required && errors.push('File is required.');
      return errors
    },
    licensesCountComputed(){
      return this.licensesCount.map(count => {
        return {
          ...count,
          status: !count.status ? 'Inactive' :  count.status === 1 ? 'Available' : 'Active'
        }
      });
    }
  },
  methods: {
    countColor( status ){
      return status === 'Available' ? '#3f51b5' : status === 'Active' ? '#4caf50' : '#555'
    },
    openDialog(){
      this.dialog = true;
      this.$v.$reset();
      this.model.file = null;
    },
    getLicenses() {
      this.loading = true;
      const sortBy = this.options.sortBy && this.options.sortBy.length ? '&order=' +  this.options.sortBy[0] +  '&desc=' + this.options.sortDesc[0] : '';
      this.axios.get(process.env.VUE_APP_API_URL + "/licenses?items_per_page=" + this.options.itemsPerPage + "&page=" + this.options.page + sortBy)
          .then(response => {
            this.total = response.data.meta.total
            this.items = response.data.data
            this.licensesCount = response.data.count;
            this.loading = false;
          }).catch(error => {
        this.loading = false;
        console.log(error.response)
      });
    },
    uploadLicenses() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log(this.model.file);
        // const data = {
        //   name: this.model.name,
        //   file: this.model.file.file,
        // };
        let formData = new FormData();
        formData.append("file", this.model.file);
        this.axios.post(process.env.VUE_APP_API_URL + "/upload-licenses", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        }).then(() => {
          Swal.fire({
            text: 'Licenses Uploaded!',
            icon: 'success',
            confirmButtonColor: '#77BB22',
            confirmButtonText: 'Close',
            showCloseButton: true
          })
          this.dialog = false;
          this.getLicenses();
        }).catch(error => {
              Swal.fire({
                text: 'Error uploading Licenses! (' + error.response.data.errors + ')',
                icon: 'error',
                confirmButtonColor: '#77BB22',
                confirmButtonText: 'Close',
                showCloseButton: true
              })
              this.serverErrors = error.response.data.errors;
              this.hasServerErrors = true;
            }
        );
      }
    }
  },
  created() {
    this.loading = true
    this.getLicenses();
  },
}
</script>
